import { useEffect, useState } from "react";

function NotePage(props) {
  const [notes, setNotes] = useState([]);
  const [noteInput, setNoteInput] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    fetch("/api/notes", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setNotes(res.data);
        setNoteInput({
          title: "",
          content: "",
        });
      });
  }, [isUpdate]);

  function updateNote(note_id) {
    fetch(`/api/notes/${note_id}`, {
      method: "PUT",
      body: JSON.stringify({
        title: noteInput.title,
        content: noteInput.content,
      }),
    }).then((res) => {
      setIsUpdate(!isUpdate);
      setNoteInput({
        title: "",
        content: "",
      });
    });
  }

  function addNote() {
    let note = {
      title: noteInput.title,
      content: noteInput.content,
    };
    fetch("/api/notes", {
      method: "POST",
      body: JSON.stringify(note),
    }).then((res) => {
      setIsUpdate(!isUpdate);
      setNoteInput({
        title: "",
        content: "",
      });
    });
  }

  function submitForm(e) {
    e.preventDefault();
    if (noteInput.note_id) {
      updateNote(noteInput.note_id);
    } else {
      addNote();
    }
  }

  function deleteNote(note_id) {
    fetch(`/api/notes/${note_id}`, { method: "DELETE" }).then((res) => {
      setIsUpdate(!isUpdate);
    });
  }

  return (
    <>
      <div className="container">
        <h1>My Note-Taking Page</h1>
        <div className="note-form">
          <form id="noteForm" onSubmit={submitForm}>
            <input
              type="text"
              id="titleInput"
              placeholder="Note Title"
              value={noteInput.title}
              required
              onChange={(e) =>
                setNoteInput({ ...noteInput, title: e.target.value })
              }
            />
            <textarea
              id="contentInput"
              placeholder="Note Content"
              value={noteInput.content}
              required
              onChange={(e) =>
                setNoteInput({ ...noteInput, content: e.target.value })
              }
            ></textarea>
            <input type="submit" value="Add Note" />
          </form>
        </div>
        <div className="note-list" id="noteList">
          {notes.map((note) => {
            return (
              <>
                <div className="note-list" id="noteList">
                  <div className="note">
                    <h2> {note.title}</h2>
                    <p> {note.content} </p>
                    <div className="note-actions">
                      <button onClick={() => deleteNote(note.note_id)}>
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          setNoteInput(note);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default NotePage;
